import { Button, notification } from 'antd';
import { useNewVersionCheck } from './useNewVersionCheck';
import { useEffect } from 'react';

export function NewVersionNotification() {
  const update = () => window.location.reload();

  const { show, onIgnored } = useNewVersionCheck({
    onUpdate: update,
  });

  useEffect(() => {
    if (show) {
      notification.open({
        placement: 'bottomLeft',
        message: 'A new update is available.',
        description: 'Refresh to get the latest version.',
        duration: 0,
        btn: (
          <Button type={'primary'} onClick={update}>
            Refresh
          </Button>
        ),
        key: 'new_solv_version_available',
        onClose: onIgnored,
      });
    } else {
      notification.close('new_solv_version_available');
    }
  }, [onIgnored, show]);

  return null;
}
