import { useWindowResize, useThrottledCallback } from 'beautiful-react-hooks';
import { useMemo, useState } from 'react';
import { breakpoints } from '../components/primitives/constants';

export type UseBreakPointsReturn = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  tablet: boolean;
  lg: boolean;
  xl: boolean;
};

export const BreakpointSizes: Array<keyof typeof breakpoints> = [
  'xl',
  'lg',
  'tablet',
  'md',
  'sm',
  'xs',
];

export function useBreakpoints(): UseBreakPointsReturn {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  useWindowResize(
    useThrottledCallback((event) => {
      setWidth(window.innerWidth);
    })
  );

  return useMemo(() => {
    const temp = {
      lg: false,
      md: false,
      sm: false,
      tablet: false,
      xl: false,
      xs: false,
    };

    for (const point of BreakpointSizes) {
      if (width >= breakpoints[point]) {
        temp[point] = true;
        break;
      }
    }

    return temp;
  }, [width]);
}
