import React from 'react';
import moment from 'moment';
import Head from 'next/head';
import { ConfigProvider } from 'antd';
import { NewVersionNotification } from '../NewVersion/NewVersionNotification';
import { isDev } from '../../config';
import { DebugBreakpointViewer } from './Debug/DebugBreakpointViewer';
import { useReduxMigrate } from '../../store/useReduxMigrate';
import { AuthProvider, AuthProviderProps } from './AuthProvider';
import { FullPageSpinner } from './FullPageSpinner';
import { IntercomChat } from '../Intercom/IntercomChat';

moment.locale('en');

export type LayoutProps = AuthProviderProps & {
  auth?: boolean;
  children?: any;
  error?: any;
  title: string;
  loading?: boolean;
  hideIntercom?: boolean;
};

export default function Layout(props: LayoutProps) {
  useReduxMigrate();

  if (props.error) {
    return props.children;
  }

  return (
    <Wrapper {...props}>
      {<IntercomChat hide={!!props.hideIntercom || !props.auth} />}
      <NewVersionNotification />
      <Head>
        <title>{props.title}</title>
      </Head>
      <ConfigProvider locale={undefined}>
        {isDev() && <DebugBreakpointViewer />}
        {props.loading ? <FullPageSpinner /> : <div>{props.children}</div>}
      </ConfigProvider>
    </Wrapper>
  );
}

const Wrapper = (props: Partial<LayoutProps>) => {
  if (props.auth) {
    return <AuthProvider {...props}>{props.children}</AuthProvider>;
  }
  return <div>{props.children}</div>;
};
