import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalEvent, useInterval } from 'beautiful-react-hooks';
import { HttpError, httpPostJson } from '../../core/http/http';
import { DapiSingleResult } from '../../core/dapi/response';
import { LoginToken, RefreshTokenResponse, RootState } from '../../types/RootState';
import { getLoginUrl } from '../../core/dapi/login';
import { getAuth, removeAuth, setAuth } from '../../core/auth';
import { DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config';
import { FullPageSpinner } from './FullPageSpinner';
import { retryAsync } from '../../core/util/retry';
import { log } from '../../core/logger/log';
import { useRouter } from 'next/router';
import { clearPersistedStore, store } from '../../store/configureStore';
import { onLoginSuccess } from '../../ducks/login';
import { useDispatch } from '../../types/Redux';
import { identifyUserForFlags } from '../../core/flags/flags';
import { analyticsTrackEvent } from '../../core/analytics';

const buildLoginRefresh = (refreshToken: string) => ({
  client_id: DAPI_CLIENT_ID,
  client_secret: DAPI_CLIENT_SECRET,
  grant_type: 'refresh_token',
  refresh_token: refreshToken,
});

const NEXT_REFRESH_TIME = 30 * 60 * 1000;

enum ValidateTokenResult {
  Success,
  AccountNotSet,
  AccountIdNotMatchToken,
  InvalidToken,
}

const renewDapiToken = async () => {
  /*
    Sometimes this can fail due to race conditions if the token expires as your refreshing it, so retrying or good measure
   */
  await retryAsync(async () => {
    // Grabbing fresh everytime from storage, just in case the passed in version is stale
    const auth = getAuth();
    if (!auth) {
      return;
    }
    console.debug('refreshing dapi auth token');
    const postData = buildLoginRefresh(auth.refresh_token);
    const result = await httpPostJson<DapiSingleResult<RefreshTokenResponse>>(
      getLoginUrl(),
      postData,
      {
        ignoreAuth: true,
      }
    );
    setAuth({
      ...result.data,
      clinic_account_id: auth.clinic_account_id,
    });
  }, 3);
};

/*
  Run some basic checks to ensure the auth token matches that account we have stored in redux
 */
const validateToken = (auth: LoginToken): ValidateTokenResult => {
  const state = store.getState() as unknown as RootState;

  if (!state.login || !state.login?.account?.id) {
    return ValidateTokenResult.AccountNotSet;
  }

  if (state.login.account.id !== auth.clinic_account_id) {
    return ValidateTokenResult.AccountIdNotMatchToken;
  }

  if (!Boolean(auth.clinic_account_id && auth.access_token && auth.refresh_token)) {
    return ValidateTokenResult.InvalidToken;
  }

  return ValidateTokenResult.Success;
};

export type AuthProviderProps = {
  allowRedirectBackAfterAuth?: boolean;
};

export function AuthProvider({
  children,
  allowRedirectBackAfterAuth,
}: { children: any } & AuthProviderProps) {
  const [step, setStep] = useState<'loading' | 'failed' | 'success'>('loading');

  const router = useRouter();
  const dispatch = useDispatch();

  const redirectQs = useMemo(() => {
    return allowRedirectBackAfterAuth ? `?redirect_url=${btoa(router.asPath)}` : '';
  }, [allowRedirectBackAfterAuth, router.asPath]);

  const onTokenValid = () => {
    const state = store.getState() as unknown as RootState;
    const account = state.login.account;
    identifyUserForFlags(account).finally(() => {
      setStep('success');
    });
  };

  useGlobalEvent('storage', (event: StorageEvent) => {
    // dapi auth key was cleared, probably another tab logging out, force redirect to login
    if (
      event.storageArea === localStorage &&
      event.key === 'dapi_authorization' &&
      !event.newValue
    ) {
      router.push(`/login${redirectQs}`);
    }
  });

  useEffect(() => {
    /*
    If no auth or auth is invalid, kick them to login screen
     */
    const auth = getAuth();

    if (!auth?.clinic_account_id) {
      router.push(`/login${redirectQs}`);
      return;
    }

    const validate = validateToken(auth);

    if (
      validate === ValidateTokenResult.AccountIdNotMatchToken ||
      validate === ValidateTokenResult.InvalidToken
    ) {
      router.push(`/login${redirectQs}`);
      return;
    }

    /*
      They have the token in local storage, but the account is not in redux state.
      This likely happens if they open a new tab up because redux state is in session storage, which isn't shared.
      Let's just run them through the onLoginSuccess to populate the store and redirect them
     */
    if (validate === ValidateTokenResult.AccountNotSet) {
      onLoginSuccess(dispatch, auth, {
        redirectUrl: router.asPath,
      })
        .catch((err) => {
          if (err instanceof HttpError) {
            const status = err.getStatusCode();
            // Token must be expired, lets try to refresh it
            if (status === 401) {
              renewDapiToken()
                .then(() => {
                  const auth = getAuth();
                  if (auth) {
                    // Try to do onLoginSuccess again so the redux store gets populated
                    onLoginSuccess(dispatch, auth)
                      .catch(() => {
                        setStep('failed');
                      })
                      .then(onTokenValid);
                  }
                })
                // Failed to renew, token must be messed up
                .catch(() => {
                  setStep('failed');
                });
            }
          } else {
            setStep('failed');
          }
        })
        .finally(() => {
          onTokenValid();
        });
      return;
    }

    /*
    If authed, check if we should refresh
     */
    const lastRefresh = auth.meta?.last_refresh;
    if (lastRefresh) {
      const diff = Date.now() - lastRefresh;
      const minutesLeft = Math.round((NEXT_REFRESH_TIME - diff) / 60 / 1000);
      if (minutesLeft > 0) {
        console.debug(
          `already refreshed recently, not refreshing again for ${minutesLeft} minutes.`
        );
        onTokenValid();
        return;
      }
    }
    /**
     * If should refresh, renew the dapi token
     */
    // Refresh the dapi token if it hasn't been refreshed within the last 30 minutes
    renewDapiToken()
      .then(() => {
        onTokenValid();
      })
      .catch((err) => {
        // Failed after 3 attempts, it's possible the refresh token is too old, lets just show them a message
        log.error(err);
        setStep('failed');
      });
  }, [allowRedirectBackAfterAuth, dispatch, redirectQs, router]);

  useInterval(async () => {
    // Renew it every 30 mins.
    try {
      await renewDapiToken();
    } catch (e) {
      // noop
    }
  }, NEXT_REFRESH_TIME);

  useEffect(() => {
    if (step === 'failed') {
      analyticsTrackEvent('AUTH_PROVIDER_AUTHENTICATION_FAILED', {
        clinicAccountId: getAuth()?.clinic_account_id ?? '',
      });
      // Just do a full reload because something is messed up.
      clearPersistedStore();
      removeAuth();
      window.location.replace(`/login${redirectQs}`);
    }
  }, [redirectQs, router, step]);

  if (step === 'loading' || step === 'failed') {
    return <FullPageSpinner />;
  }

  return children;
}
