import { BreakpointSizes, useBreakpoints } from '../../../hooks/useBreakpoints';
import styled from 'styled-components';
import { Typography } from 'antd';

const Breakpoints = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  background-color: #111111;
`;

export function DebugBreakpointViewer() {
  const { xs, sm, lg, tablet, xl, md } = useBreakpoints();
  const sizesVal = [xl, lg, tablet, md, sm, xs];

  return (
    <Breakpoints>
      {BreakpointSizes.map((size, index) => {
        return (
          <Typography.Text
            key={size}
            style={{
              fontWeight: sizesVal[index] ? 'bolder' : 'normal',
              color: sizesVal[index] ? '#ff43a2' : 'gray',
              display: 'inline',
              fontSize: '13pt',
              padding: '4px',
            }}
          >
            {size}
          </Typography.Text>
        );
      })}
    </Breakpoints>
  );
}
