import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from '../../types/Redux';
import { useIdleTimer } from 'react-idle-timer';
import { analyticsTrackEvent } from '../../core/analytics';
import { useInterval } from 'beautiful-react-hooks';
import moment from 'moment';
import { ignoreUpdatePrompt } from '../../ducks/newVersion';
import { MANAGE_UPDATE } from '../../core/analytics/events';
import { isProd } from '../../config';

export function useNewVersionCheck({ onUpdate }: { onUpdate: () => void }): {
  canUpdate: boolean;
  onIgnored: () => void;
  show: boolean;
} {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [isIdle, setIdle] = useState(false);
  const ignoring = useRef(false);

  const updateAvailableShowAlertTime = useSelector(
    (w) => w.newVersion.updateAvailableShowAlertTime
  );

  const mode = useSelector((w) => w.newVersion.mode);

  useIdleTimer({
    timeout: !isProd() ? 10 * 1000 : 1000 * 60 * 10,
    debounce: 500,
    onActive: () => {
      if (isIdle) {
        setIdle(false);
      }
    },
    onIdle: () => {
      if (!isIdle) {
        setIdle(true);
      }
    },
  });

  const doUpdate = useCallback(
    ({ force }: { force: boolean } = { force: false }) => {
      // We don't want to force refresh on telemed calls as it would interrupt the call
      if (force && mode === 'telemed') {
        return;
      }

      analyticsTrackEvent(MANAGE_UPDATE, {
        showTime: updateAvailableShowAlertTime,
        action: force ? 'forced' : 'clicked',
      });
      onUpdate();
    },
    [mode, onUpdate, updateAvailableShowAlertTime]
  );

  const doUpdateOnIdle = useCallback(() => {
    if (!canUpdate || !isIdle) {
      return;
    }

    // If we are on the kiosk and idle, just force the update.
    if (mode === 'kiosk') {
      return doUpdate({ force: true });
    }

    const currentHour = new Date().getHours();
    // If it is between 1 - 4 AM, we should just force refresh to update older versions that are just left on.
    if (currentHour >= 1 && currentHour <= 4) {
      return doUpdate({ force: true });
    }
  }, [canUpdate, doUpdate, isIdle, mode]);

  useEffect(() => {
    if (canUpdate && isIdle) {
      doUpdateOnIdle();
    }
  }, [canUpdate, doUpdateOnIdle, isIdle]);

  useInterval(() => {
    // Return here so we aren't checking for an update when its processing the ignore.
    if (ignoring.current) {
      return;
    }

    const isUpdateAvailable = updateAvailableShowAlertTime
      ? moment(updateAvailableShowAlertTime).isBefore(moment())
      : false;

    const shouldShow = canUpdate && mode === 'normal';

    if (canUpdate !== isUpdateAvailable) {
      setCanUpdate(isUpdateAvailable);
    }

    if (show !== shouldShow) {
      setShow(shouldShow);
    }
  }, 5000);

  return {
    show,
    canUpdate,
    onIgnored: () => {
      try {
        ignoring.current = true;
        setCanUpdate(false);
        setShow(false);
        dispatch(ignoreUpdatePrompt());
      } finally {
        ignoring.current = false;
      }
    },
  };
}
